import * as React from "react";

export const showAllNSFWKey = "showAllNSFW";

export default function useShowAllNSFW() {
  const [showAllNSFW, setShowAllNSFW] = React.useState<boolean | null>(null);

  const loadShowAllNSFW = (key: string) => {
    const text = localStorage.getItem(key);
    if (text == null) {
      return null;
    }
    try {
      return JSON.parse(text) === true;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const saveShowAllNSFW = (showAllNSFW: boolean | null | undefined) => {
    if (showAllNSFW == null) {
      localStorage.removeItem(showAllNSFWKey);
    } else {
      localStorage.setItem(showAllNSFWKey, showAllNSFW.toString());
    }
  };

  React.useEffect(() => {
    setShowAllNSFW(loadShowAllNSFW(showAllNSFWKey));
  }, []);

  React.useEffect(() => {
    const loadedShowAllNSFW = loadShowAllNSFW(showAllNSFWKey);
    if (showAllNSFW != null && loadedShowAllNSFW !== showAllNSFW) {
      saveShowAllNSFW(showAllNSFW);
    }
  }, [showAllNSFW]);

  return { showAllNSFW, setShowAllNSFW };
}
