import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function PageLayout$Divider({ className, style }: Props) {
  return <hr className={cx(styles.container, className)} style={style}></hr>;
}
