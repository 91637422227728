import { fromJson, toJson } from "kreate-common/modules/json-utils";

import { Params, Result } from "./typing";

export async function httpPost(url: string, params: Params): Promise<Result> {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: toJson(params),
  });
  if (!response.ok) {
    throw new Error(
      "response not ok: " + toJson({ status: response.status, url, params })
    );
  }
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export const httpPost$LoveUser = httpPost;
