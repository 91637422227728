import { withDefaultProps } from "../../hoc/withDefaultProps";
import CustomButton, { CustomButton$Props } from "../CustomButton";

type Props = CustomButton$Props & React.RefAttributes<HTMLButtonElement>;

export type ButtonSecondary$Props = Props;

const ButtonSecondary: React.ForwardRefExoticComponent<Props> =
  CustomButton.withDefaultProps({
    color: "secondary",
  });

export default Object.assign(ButtonSecondary, {
  withDefaultProps: (
    defaultProps: Partial<Props>
  ): React.ForwardRefExoticComponent<Props> =>
    withDefaultProps(defaultProps)(ButtonSecondary),
});
