import SizingContainer from "common-utils/components/SizingContainer";
import { User } from "kreate-common/modules/business-types";
import ButtonOutline from "kreate-common/modules/kreate-ui/components/ButtonOutline";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { MdSubdirectoryArrowRight } from "react-icons/md";

import styles from "./index.module.scss";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";
import { EM_DASH } from "@/modules/common-utils/unicode";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import ButtonLoveArtist from "@/modules/kreate-components/components/ButtonLoveArtist";
import SecondaryFileViewer from "@/modules/kreate-components/components/SecondaryFileViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import useShowAllNSFW from "@/modules/kreate-components/components/WithArtworkCensorship/hooks/useShowAllNSFW";
import useShowAllPhotosensitivity from "@/modules/kreate-components/components/WithArtworkCensorship/hooks/useShowAllPhotosensitivity";
import { getLinkToPageProfile } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artist: User;
  // Show buttons to love, etc.?
  enableActions: boolean;
  openInNewTab?: boolean;
};

export default function ArtistCard({
  className,
  style,
  artist,
  enableActions,
  openInNewTab = false,
}: Props) {
  const router = useRouter();
  const loginStatus = useLoginStatus();
  const { showAllNSFW } = useShowAllNSFW();
  const { showAllPhotosensitivity } = useShowAllPhotosensitivity();
  return (
    <div className={className} style={style}>
      <Flex.Row justifyContent="space-between" flexWrap="wrap" gap="20px">
        <Flex.Col gap="16px" flex="0 0 360px" minWidth={0}>
          <Flex.Row gap="16px">
            <Flex.Cell className={styles.avatarContainer} flex="0 0 auto">
              <Link
                href={getLinkToPageProfile(artist)}
                target={openInNewTab ? "_blank" : undefined}
                rel={openInNewTab ? "noreferrer" : undefined}
              >
                <AvatarViewer value={artist?.avatar} size="72px" />
              </Link>
            </Flex.Cell>
            <Flex.Col justifyContent="center" minWidth={0}>
              <UserDisplayNameViewerV2
                user={artist}
                className={styles.nameViewer}
                openInNewTab={openInNewTab}
                unstyled={true}
              />
              <div className={styles.work}>
                <span>{`${artist?.numTotalArtworks || EM_DASH} Works`}</span>
                <span className={styles.dot}>{" • "}</span>
                <span>{`${artist?.lovedBy.length ?? EM_DASH} Loves`}</span>
              </div>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row gap="12px">
            <ButtonOutline
              icon={<MdSubdirectoryArrowRight />}
              onClick={() =>
                openInNewTab
                  ? window.open(
                      getLinkToPageProfile(artist),
                      "_blank",
                      "noreferrer"
                    )
                  : router.push(getLinkToPageProfile(artist))
              }
            >
              {"View Profile"}
            </ButtonOutline>
            {enableActions && loginStatus?.loggedIn ? (
              <ButtonLoveArtist artist={artist} />
            ) : null}
          </Flex.Row>
        </Flex.Col>
        <Flex.Cell className={styles.imageRowContainer} flex="1 1 175px">
          <Flex.Row
            gap="10px"
            flex="1 1 0"
            flexWrap="wrap"
            justifyContent="flex-end"
          >
            {artist.artworks.map((artwork) => (
              <Link
                href={`/artwork/${artwork.artworkId}`}
                key={artwork.artworkId}
                className={styles.artworkContainer}
                target={openInNewTab ? "_blank" : undefined}
                rel={openInNewTab ? "noreferrer" : undefined}
              >
                <SizingContainer sizing={{ type: "fill" }}>
                  <SecondaryFileViewer
                    className={
                      (artwork.isNSFW && !showAllNSFW) ||
                      (artwork.flagPhotosensitivity && !showAllPhotosensitivity)
                        ? styles.censored
                        : undefined
                    }
                    file={artwork?.file}
                    thumbnailFile={artwork?.imageFile}
                    sizes="180px"
                    objectFit="cover"
                    fill
                  />
                </SizingContainer>
              </Link>
            ))}
          </Flex.Row>
        </Flex.Cell>
      </Flex.Row>
    </div>
  );
}
