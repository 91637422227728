import { User } from "kreate-common/modules/business-types";
import { DisplayableError } from "kreate-common/modules/displayable-error";
import ButtonOutline from "kreate-common/modules/kreate-ui/components/ButtonOutline";
import { httpPost$LoveUser } from "kreate-server/commands/LoveUser/fetcher";
import * as React from "react";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";
import { shamelesslyRevalidateEverything } from "@/modules/common-utils/shamelesslyRevalidateEverything";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  artist: User | null | undefined;
};

export default function ButtonLoveArtist({ className, style, artist }: Props) {
  const loginStatus = useLoginStatus();
  const [pendingLoveState, setPendingLoveState] = React.useState<
    boolean | null
  >(null);
  const isLoved =
    loginStatus?.loggedIn && artist?.lovedBy?.includes(loginStatus.userId);
  return (
    <ButtonOutline
      className={className}
      style={style}
      content="Love"
      icon={
        (pendingLoveState != null ? pendingLoveState : isLoved) ? (
          <MdFavorite color="#d02f2d" />
        ) : (
          <MdFavoriteBorder />
        )
      }
      disabled={pendingLoveState !== null}
      onClick={async () => {
        setPendingLoveState(!isLoved);
        try {
          await httpPost$LoveUser(`/api/v1/user/${artist?.userId}/love`, {
            love: !isLoved,
          });
          await shamelesslyRevalidateEverything();
        } catch (error) {
          const displayableError = DisplayableError.from(error);
          // TODO: @kien-kreate: display a toast properly
          alert(displayableError.message);
        } finally {
          setPendingLoveState(null);
        }
      }}
    >
      {"Love"}
    </ButtonOutline>
  );
}
