import cx from "clsx";
import { FileHandle } from "kreate-common/modules/business-types";
import React from "react";

import { clamp } from "../../utils/arithmetic";

import AutoHeight from "./components/AutoHeight";
import styles from "./index.module.scss";

const MAX_ASPECT_RATIO = 4;
const MIN_ASPECT_RATIO = 1 / MAX_ASPECT_RATIO;

const BACKGROUND = {
  default: styles.backgroundDefault,
  none: "",
};

type Length = `${number}${"px" | "em"}`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  sizing:
    | { type: "fill" }
    | { type: "fixed"; width: number | Length; height: number | Length }
    | { type: "auto-height"; aspectRatio: number | FileHandle };
  children?: React.ReactNode;
  background?: keyof typeof BACKGROUND | string[];
};

function Root({
  className,
  style,
  sizing,
  children,
  background = "default",
}: Props) {
  switch (sizing.type) {
    case "fill":
      return (
        <div
          className={cx(
            styles.fill,
            className,
            Array.isArray(background) ? background : BACKGROUND[background]
          )}
          style={style}
        >
          {children}
        </div>
      );

    case "fixed":
      return (
        <div
          className={cx(
            styles.fixed,
            className,
            Array.isArray(background) ? background : BACKGROUND[background]
          )}
          style={Object.assign(
            { ...style },
            {
              "--width":
                typeof sizing.width === "number"
                  ? `${sizing.width}px`
                  : sizing.width,
              "--height":
                typeof sizing.height === "number"
                  ? `${sizing.height}px`
                  : sizing.height,
            }
          )}
        >
          {children}
        </div>
      );

    case "auto-height":
      return (
        <div
          className={cx(
            styles.autoHeight,
            className,
            Array.isArray(background) ? background : BACKGROUND[background]
          )}
          style={Object.assign(
            { ...style },
            { "--aspect-ratio": `${toAspectRatio(sizing.aspectRatio)}` }
          )}
        >
          {children}
        </div>
      );
  }
}

function toAspectRatio(value: number | FileHandle) {
  if (typeof value === "number") {
    return clamp(MIN_ASPECT_RATIO, value, MAX_ASPECT_RATIO);
  }

  return value.blob.width && value.blob.height
    ? clamp(
        MIN_ASPECT_RATIO,
        value.blob.width / value.blob.height,
        MAX_ASPECT_RATIO
      )
    : 1.0;
}

const SizingContainer = Object.assign(Root, { AutoHeight });

export default SizingContainer;
