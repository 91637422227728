import cx from "clsx";
import React from "react";
import { RemoveScroll } from "react-remove-scroll";

import Navbar$Min0px from "../NavbarV2/containers/Navbar$Min0px";
import Navbar$Min1200px from "../NavbarV2/containers/Navbar$Min1200px";
import Navbar$Min768px from "../NavbarV2/containers/Navbar$Min768px";

import RibbonCarousel from "./containers/RibbonCarousel";
import { useRibbonCarouselApi } from "./containers/RibbonCarousel/hooks";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  hideSearchBarOnNavbar?: boolean;
  makeTransparentUnlessScrolledDown?: boolean;
  hideRibbonCarousel?: boolean;
};

const THRESHOLD = 0.9;
const EPSILON = Math.exp(-4); // ~ 0.6%

// Similar to NavbarV2, but displays RibbonCarousel
export default function NavbarV3({
  className,
  style,
  hideSearchBarOnNavbar,
  makeTransparentUnlessScrolledDown,
  hideRibbonCarousel,
}: Props) {
  const ribbonCarouselApi = useRibbonCarouselApi({
    disabled: hideRibbonCarousel,
  });

  const [container, setContainer] = React.useState<HTMLElement | null>(null);
  const [isScrolledDown, setIsScrollDown] = React.useState(false);

  React.useEffect(() => {
    if (!container) return;

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const newIsScrollDown = entries.every(
          (entry) => entry.intersectionRatio < THRESHOLD
        );
        isScrolledDown !== newIsScrollDown && setIsScrollDown(newIsScrollDown);
      },
      {
        rootMargin: "0px",
        threshold: [THRESHOLD - EPSILON, THRESHOLD, THRESHOLD + EPSILON],
      }
    );

    intersectionObserver.observe(container);
  }, [container, isScrolledDown]);

  const renderContent = () => {
    return (
      <>
        {!hideRibbonCarousel ? (
          <RibbonCarousel api={ribbonCarouselApi} />
        ) : undefined}
        <Navbar$Min0px className={styles.navbar0} />
        <Navbar$Min768px className={styles.navbar768} />
        <Navbar$Min1200px
          className={styles.navbar1200}
          hideSearchBarOnNavbar={hideSearchBarOnNavbar}
        />
      </>
    );
  };

  return (
    <div
      ref={setContainer}
      className={cx(styles.container, className)}
      style={{
        ...style,
        ...(!isScrolledDown && makeTransparentUnlessScrolledDown
          ? { "--navbar-background-color": "transparent" }
          : undefined),
      }}
    >
      <div
        className={cx(
          styles.fixedContainer,
          // https://github.com/theKashey/react-remove-scroll#positionfixed-elements
          RemoveScroll.classNames.zeroRight
        )}
      >
        {renderContent()}
      </div>
      <div className={styles.staticContainer}>{renderContent()}</div>
    </div>
  );
}
