import { UserId } from "kreate-common/modules/business-types";
import { z } from "zod";

export const Params = z.object({
  love: z.boolean(),
});

export type Params = z.infer<typeof Params>;

export const Result = z.object({
  userId: UserId,
});

export type Result = z.infer<typeof Result>;

export type LoveUser$Params = Params;
export const LoveUser$Params = Params;
export type LoveUser$Result = Result;
export const LoveUser$Result = Result;
