import cx from "clsx";
import * as React from "react";

import styles from "./index.module.scss";

type ClassName = string;

const SIZE = {
  default: styles.sizeDefault,
  sm: styles.sizeSm,
};

const BACKGROUND = {
  default: styles.backgroundDefault,
  white: styles.backgroundWhite,
};

const PADDING = {
  default: styles.paddingDefault,
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;

  // display props
  size?: keyof typeof SIZE | ClassName[];
  background?: keyof typeof BACKGROUND | ClassName[];
  padding?: keyof typeof PADDING | ClassName[];

  // css props
  marginTop?: React.CSSProperties["marginTop"];
  marginBottom?: React.CSSProperties["marginBottom"];
  paddingTop?: React.CSSProperties["paddingTop"];
  paddingBottom?: React.CSSProperties["paddingBottom"];
};

type ForwardedProps = React.HTMLAttributes<HTMLDivElement>;

type Props = OwnProps & Omit<ForwardedProps, keyof OwnProps>;

const PageLayout$Section = React.forwardRef<HTMLDivElement, Props>(
  function PageLayout$Section(
    {
      className,
      style,
      content,
      children = content,

      // display props
      size = "default",
      background = "default",
      padding = "default",

      // css props
      marginTop,
      marginBottom,
      paddingTop,
      paddingBottom,
      ...others
    },
    ref
  ) {
    return (
      <div
        ref={ref}
        className={cx(
          styles.container,
          className,
          Array.isArray(size) ? size : SIZE[size],
          Array.isArray(background) ? background : BACKGROUND[background],
          Array.isArray(padding) ? padding : PADDING[padding]
        )}
        style={{ marginTop, marginBottom, paddingTop, paddingBottom, ...style }}
        {...others}
      >
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
);

export default PageLayout$Section;
