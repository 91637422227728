import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  aspectRatio: number;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  content?: React.ReactNode;
  children?: React.ReactNode;
  backdrop?: boolean;
};

export default function AutoHeight({
  className,
  style,
  aspectRatio,
  minAspectRatio,
  maxAspectRatio,
  content,
  children = content,
  backdrop = true,
}: Props) {
  if (maxAspectRatio) {
    aspectRatio = Math.min(aspectRatio, maxAspectRatio);
  }

  if (minAspectRatio) {
    aspectRatio = Math.max(aspectRatio, minAspectRatio);
  }

  return (
    <div
      className={cx(
        styles.container,
        className,
        backdrop ? styles.backdrop : undefined
      )}
      style={
        {
          ...style,
          "--aspect-ratio": `${aspectRatio}`,
        } as React.CSSProperties
      }
    >
      {children}
    </div>
  );
}
