import cx from "clsx";
import useBodyClasses from "kreate-common/modules/common-hooks/useBodyClasses";
import React from "react";

import styles from "./index.module.scss";

import Footer from "@/modules/kreate-components/components/Footer";
import KreateHead from "@/modules/kreate-components/components/KreateHead";
import NavbarV2 from "@/modules/kreate-components/components/NavbarV2";
import NavbarV3 from "@/modules/kreate-components/components/NavbarV3";

type ClassName = string;

const BACKGROUND = {
  default: styles.backgroundDefault,
  light: styles.backgroundLight,
  white: styles.backgroundWhite,
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;

  // head props
  title: string;
  description?: string;
  imageUrl?: string;
  url?: string;

  // display props
  showNavbar?: boolean;
  hideSearchBarOnNavbar?: boolean;
  showFooter?: boolean;
  minHeight100vh?: boolean;
  background?: keyof typeof BACKGROUND | ClassName[];
  shouldUseNavbarV3?: boolean;
  makeTransparentUnlessScrolledDown?: boolean;
};

export default function PageLayout$Root({
  className,
  style,
  content,
  children = content,

  // head props
  title,
  description,
  imageUrl,
  url,

  // display props
  showNavbar = true,
  hideSearchBarOnNavbar = false,
  showFooter = true,
  minHeight100vh = true,
  background = "default",
  shouldUseNavbarV3 = false,
  makeTransparentUnlessScrolledDown = false,
}: Props) {
  useBodyClasses([styles.body]);

  return (
    <article
      className={cx(
        styles.container,
        className,
        minHeight100vh ? styles.minHeight100vh : null,
        Array.isArray(background) ? background : BACKGROUND[background]
      )}
      style={style}
    >
      <KreateHead
        title={title}
        description={description}
        imageUrl={imageUrl}
        url={url}
      />
      {!showNavbar ? undefined : shouldUseNavbarV3 ? (
        // NavbarV3 displays the ribbon
        <NavbarV3
          hideSearchBarOnNavbar={hideSearchBarOnNavbar}
          makeTransparentUnlessScrolledDown={makeTransparentUnlessScrolledDown}
        />
      ) : (
        <NavbarV2 hideSearchBarOnNavbar={hideSearchBarOnNavbar} />
      )}
      <div className={styles.content}>{children}</div>
      {showFooter ? <Footer /> : null}
    </article>
  );
}
