import PageLayout$Divider from "./components/Divider";
import PageLayout$Root from "./components/Root";
import PageLayout$Section from "./components/Section";

const PageLayout = Object.assign(PageLayout$Root, {
  Root: PageLayout$Root,
  Section: PageLayout$Section,
  Divider: PageLayout$Divider,
});

export default PageLayout;
