import * as React from "react";

export const showAllPhotosensitivityKey = "showAllPhotosensitivity";

export default function useShowAllPhotosensitivity() {
  const [showAllPhotosensitivity, setShowAllPhotosensitivity] = React.useState<
    boolean | null
  >(null);

  const loadShowAllPhotosensitivity = (key: string) => {
    const text = localStorage.getItem(key);
    if (text == null) {
      return null;
    }
    try {
      return JSON.parse(text) === true;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const saveShowAllPhotosensitivity = (
    showAllPhotosensitivity: boolean | null | undefined
  ) => {
    if (showAllPhotosensitivity == null) {
      localStorage.removeItem(showAllPhotosensitivityKey);
    } else {
      localStorage.setItem(
        showAllPhotosensitivityKey,
        showAllPhotosensitivity.toString()
      );
    }
  };

  React.useEffect(() => {
    setShowAllPhotosensitivity(
      loadShowAllPhotosensitivity(showAllPhotosensitivityKey)
    );
  }, []);

  React.useEffect(() => {
    const loadedShowAllPhotosensitivity = loadShowAllPhotosensitivity(
      showAllPhotosensitivityKey
    );
    if (
      showAllPhotosensitivity != null &&
      loadedShowAllPhotosensitivity !== showAllPhotosensitivity
    ) {
      saveShowAllPhotosensitivity(showAllPhotosensitivity);
    }
  }, [showAllPhotosensitivity]);

  return { showAllPhotosensitivity, setShowAllPhotosensitivity };
}
