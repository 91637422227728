import cx from "clsx";
import React from "react";
import { RemoveScroll } from "react-remove-scroll";

import Navbar$Min0px from "./containers/Navbar$Min0px";
import Navbar$Min1200px from "./containers/Navbar$Min1200px";
import Navbar$Min768px from "./containers/Navbar$Min768px";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  hideSearchBarOnNavbar?: boolean;
};

export default function NavbarV2({
  className,
  style,
  hideSearchBarOnNavbar,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <div
        className={cx(
          styles.fixedContainer,
          // https://github.com/theKashey/react-remove-scroll#positionfixed-elements
          RemoveScroll.classNames.zeroRight
        )}
      >
        <Navbar$Min0px className={styles.navbar0} />
        <Navbar$Min768px className={styles.navbar768} />
        <Navbar$Min1200px
          className={styles.navbar1200}
          hideSearchBarOnNavbar={hideSearchBarOnNavbar}
        />
      </div>
    </div>
  );
}
